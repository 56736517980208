//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        
    },
    data() {
        return {
       
        }
    },
    computed: {
        list() {
            return this.$store.getters.tagNavList;
        }
    },

    methods: {
        clickHandler(item) {
            if(this.$route.fullPath === item.fullPath) return;

            this.goto(item.fullPath);
        },
        closeHandler(item, index) {
            this.$store.commit('popTagNav', index);

            this.goto(this.list[this.list.length - 1].fullPath);
        },

 
    },
    mounted(){
   
    }


}
