//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        
    },
    data () {
        return {

        }
    },
    methods: {

    },
    mounted: function() {

    }
}
