//
//
//
//
//
//

import logo from '@/assets/logo.png'
import logoS from '@/assets/logo-small.png'

export default {
    props: {
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        logoSrc() {
            return this.small ? logoS : logo;
        }
    }
}
