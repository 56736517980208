//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyBreadcrumb from './components/breadcrumb'
import UserInfo from './components/user-info'
import LogoImg from './components/logo-img'
import TagNav from './components/tag-nav'

import lessVars from '@/css/var.scss'


export default {
    components: {MyBreadcrumb, UserInfo, LogoImg, TagNav},
  
    data () {
        return {
            collapseController: false,
            showController: false,

            mobile: false,
            viewOnly: false,
            tagNav: false,

            colors: lessVars,
        }
    },
    computed: {
        menuList() {
            var type = this.$store.state.system.system.type,
                sysStore = this.$store.state[type] || {};
            return sysStore.navList || [];
            
        },
        userHeaderText(){
            if(this.collapseController == false){
                return this.$store.state.user.user.dwname ? this.$store.state.user.user.dwname : this.$store.state.user.user.truename ? this.$store.state.user.user.truename : '';
            }else{
                return this.$store.state.user.user.dwname ? this.$store.state.user.user.dwname.slice(0, 4) : this.$store.state.user.user.truename ? this.$store.state.user.user.truename.slice(0, 4) : '';
            }
        }
    },
    watch:{},
    methods: {
        toggleCollapse() {
            if(this.mobile) {
                this.toggleShowController();
                return;
            };
            this.collapseController = !this.collapseController;
        },
        toggleShowController() {
            this.showController = !this.showController;
        },
        goBack() {
            this.$router.go(-1);
        },
        resizeHandler() {
            if(window.innerWidth < 1000) {
                this.mobile = true;
            } else {
                this.mobile = false;
            };
        },
        checkFrameChild() {
            if(window !== window.top) {
                this.viewOnly = true;
            };
        },
    },
    created() {
        this.resizeHandler();
        window.addEventListener('resize', this.resizeHandler);

        this.checkFrameChild();
    },
    mounted: function() {
        // this.$store.dispatch('queryUserInfo');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    }
}
