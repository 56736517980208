//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import  kefuicno from '@assets/layout/客服.png'
import  indexicno from '@assets/layout/首页.png'
import  psdicno from '@assets/layout/修改密码.png'
import  backicno from '@assets/layout/退出.png'
import  usericno from '@assets/layout/用户.png'
export default {
    props: {

    },
    data () {
        return {
            kefuSrc:kefuicno,
            indexSrc:indexicno,
            psdSrc:psdicno,
            backSrc:backicno,
            userSrc:usericno,
            showName:'',
        }
    },
    computed: {

    },
    methods: {

        goIndex:function(){
            if(this.$route.path == '/sys/index'){
                this.$router.go(0);
            }else{
                this.$router.push('/sys/index');
            }
        },

        changePwd:function(){
            this.goto({
                path: '/sys/user/password/form'
            })
        },

        goClick:function(){
            ShowConfirm('是否确定退出', 'warning', () => {
                this.$store.commit('setUser', {});
                this.$store.commit('setSystem', {});
                this.$store.commit('setUserToken', {});
                this.$router.push('/login');
            });
        }


    },
    mounted: function() {
        // setUserId: this.$store.getters.user.userid,  //本地存的 
        // console.log(this.$store.getters.user);
        // console.log( );
        this.showName = this.getGetters('user').showName;
    }
}
